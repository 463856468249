import React from 'react'
import { Link } from 'gatsby'
import SEO from '../components/SEO'
import { Heading, Container, Section } from 'react-bulma-components';
import { FaWalking } from 'react-icons/fa';

const FAQ = () => {
  return (
    <>
      <SEO title="Vanliga frågor | Wallang" />
      <Container>
        <Section className="info-section">
          <Heading className="has-text-centered">Vanliga frågor</Heading>

          <h2 className="info-section__header">Vad finns det för olika betalalternativ?</h2>
          <p>I samarbete med Klarna erbjuder vi fakturabetalning, delbetalning, kontokortsbetalning samt direktbetalning.</p>

          <h2 className="info-section__header">Hur lång är leveranstiden för min order?</h2>
          <p>Vi skickar din order senast dagen efter du har beställt den. Sedan tillkommer leveranstid inom Sverige med utgångspunkt i Stockholm.</p>

          <h2 className="info-section__header">Hur gör jag en retur?</h2>
          <p>
            Se Ångerrätt och Retur under våra <Link to="/terms-and-conditions">Köpvillkor</Link>.
          </p>

          <h2 className="info-section__header">Vad händer om jag är missnöjd med min leverans?</h2>
          <p>
            Kontakta kundservice på <a href="mailto:info@wallang.se">info@wallang.se</a> och beskriv ditt ärende. Vi svarar inom 24 timmar.
          </p>

          <h2 className="info-section__header">Mitt paket var skadat vid leverans, vad gör jag?</h2>
          <p>
            Vid leveransskada, om du har hämtat ut paketet hos ett ombud anmäl omgående till ombudet, var noga med att dokumentera skador på ytteremballage så väl som skador på
            varan. Kontakta sedan kundtjänst på <a href="mailto:info@wallang.se">info@wallang.se</a> så hjälper vi er vidare.
          </p>
        </Section>
      </Container>
    </>
  )
}

export default FAQ;